<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <template #footer>
      <p class="mb-0 d-lg-flex d-none justify-content-between">
        <span>&copy; E8 Funding {{ new Date().getFullYear() }}, All rights reserved</span>
        <span>Created with ❤️ for trading</span>
      </p>
    </template>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    LayoutVertical,
    Navbar,
  },
}
</script>
